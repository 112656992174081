import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/base/businessOut/pageList", formData);
}

function create(){
  return request.get(constant.serverUrl + "/base/businessOut/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/base/businessOut/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/base/businessOut/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/base/businessOut/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/base/businessOut/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/base/businessOut/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function createWord(formData) {
  return request.post(constant.serverUrl + "/base/businessOut/createWord", formData);
}

function flowList(id){
  return request.post(constant.serverUrl + "/base/businessOut/flowList/" + id);
}

export default {
  pageList,create,edit,add,update,remove,batchRemove,createWord,flowList
}