
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'100px'"
      >
        <el-form-item label="" prop="id">
          <el-input
            v-model="formModel.id"
            placeholder="请输入"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="人员id" prop="personPopedomId">
          <el-input
            v-model="formModel.personPopedomId"
            placeholder="请输入人员id"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="类型（1：外出，3：出差）" prop="type">
          <el-input
            v-model="formModel.type"
            placeholder="请输入类型（1：外出，3：出差）"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="目的地" prop="destination">
          <el-input
            v-model="formModel.destination"
            placeholder="请输入目的地"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="开始时间" prop="startTime">
          <el-input
            v-model="formModel.startTime"
            placeholder="请输入开始时间"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="预估天数" prop="estimatedTime">
          <el-input
            v-model="formModel.estimatedTime"
            placeholder="请输入预估天数"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input
            v-model="formModel.content"
            placeholder="请输入内容"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="审批人id" prop="approvalPersonId">
          <el-input
            v-model="formModel.approvalPersonId"
            placeholder="请输入审批人id"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="审批状态: 0：待处理，1：同意，2：拒绝"
          prop="status"
        >
          <el-input
            v-model="formModel.status"
            placeholder="请输入审批状态: 0：待处理，1：同意，2：拒绝"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="审批编号" prop="approvalNo">
          <el-input
            v-model="formModel.approvalNo"
            placeholder="请输入审批编号"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="审核时间" prop="approvalTime">
          <el-input
            v-model="formModel.approvalTime"
            placeholder="请输入审核时间"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="审核意见" prop="approvalContent">
          <el-input
            v-model="formModel.approvalContent"
            placeholder="请输入审核意见"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="审批人id（包含历史记录）"
          prop="approvalPersonIdStr"
        >
          <el-input
            v-model="formModel.approvalPersonIdStr"
            placeholder="请输入审批人id（包含历史记录）"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="审批编号（包含历史记录）" prop="approvalNoStr">
          <el-input
            v-model="formModel.approvalNoStr"
            placeholder="请输入审批编号（包含历史记录）"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="审核时间（包含历史记录）" prop="approvalTimeStr">
          <el-input
            v-model="formModel.approvalTimeStr"
            placeholder="请输入审核时间（包含历史记录）"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="审核意见（包含历史记录）"
          prop="approvalContentStr"
        >
          <el-input
            v-model="formModel.approvalContentStr"
            placeholder="请输入审核意见（包含历史记录）"
            style="width: 300px"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import businessOutApi from "@/api/base/businessOut";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      formModel: {},
      ruleValidate: {
        id: [{ required: true, message: "不能为空", trigger: "blur" }],
        personPopedomId: [
          { required: true, message: "人员id不能为空", trigger: "blur" },
        ],
        type: [
          {
            required: true,
            message: "类型（1：外出，3：出差）不能为空",
            trigger: "blur",
          },
        ],
        destination: [
          { required: true, message: "目的地不能为空", trigger: "blur" },
        ],
        startTime: [
          { required: true, message: "开始时间不能为空", trigger: "blur" },
        ],
        estimatedTime: [
          { required: true, message: "预估天数不能为空", trigger: "blur" },
        ],
        content: [{ required: true, message: "内容不能为空", trigger: "blur" }],
        approvalPersonId: [
          { required: true, message: "审批人id不能为空", trigger: "blur" },
        ],
        status: [
          {
            required: true,
            message: "审批状态: 0：待处理，1：同意，2：拒绝不能为空",
            trigger: "blur",
          },
        ],
        approvalNo: [
          { required: true, message: "审批编号不能为空", trigger: "blur" },
        ],
        approvalTime: [
          { required: true, message: "审核时间不能为空", trigger: "blur" },
        ],
        approvalContent: [
          { required: true, message: "审核意见不能为空", trigger: "blur" },
        ],
        approvalPersonIdStr: [
          {
            required: true,
            message: "审批人id（包含历史记录）不能为空",
            trigger: "blur",
          },
        ],
        approvalNoStr: [
          {
            required: true,
            message: "审批编号（包含历史记录）不能为空",
            trigger: "blur",
          },
        ],
        approvalTimeStr: [
          {
            required: true,
            message: "审核时间（包含历史记录）不能为空",
            trigger: "blur",
          },
        ],
        approvalContentStr: [
          {
            required: true,
            message: "审核意见（包含历史记录）不能为空",
            trigger: "blur",
          },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return businessOutApi.add(self.formModel);
            } else {
              return businessOutApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return businessOutApi.create();
      } else {
        return businessOutApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>