
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
    >
      <el-table-column
        type="index"
        label="序号"
        :index="indexMethod"
        width="50"
      ></el-table-column>
      <el-table-column
        prop="approvalPersonName"
        label="审批人"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="approvalPersonRoleName"
        label="审批人角色"
        width="100"
      ></el-table-column>
      <el-table-column prop="status" label="审批状态" width="100">
        <template slot-scope="{ row }">
          <span v-if="row.status == 1" style="color: green">同意</span>
          <span v-else-if="row.status == 2" style="color: red">拒绝</span>
          <span v-else>待处理</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="approvalContent"
        label="审核意见"
        width="150"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="approvalTime"
        label="审核时间"
        width="150"
      ></el-table-column>
    </el-table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">关 闭</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import businessOutApi from "@/api/base/businessOut";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      formModel: {},
      showDialog: true,
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      submitting: false,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    indexMethod(index) {
      return (this.pageIndex - 1) * this.pageSize + (index + 1);
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      businessOutApi
        .flowList(self.businessKey)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return businessOutApi.create();
      } else {
        return businessOutApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        this.changePage(1);

        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>