<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/base/businessOut/list">外出申请记录</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="人员姓名" prop="personName">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.personName"
        ></el-input>
      </el-form-item>
      <el-form-item label="审批编号" prop="approvalNo">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.approvalNo"
        ></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select size="mini" v-model="queryModel.type">
          <el-option label="全部" value=""></el-option>
          <el-option label="外出" value="1"></el-option>
          <el-option label="出差" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="审批状态" prop="status">
        <el-select size="mini" v-model="queryModel.status">
          <el-option label="全部" value=""></el-option>
          <el-option label="未审批" value="0"></el-option>
          <el-option label="同意" value="1"></el-option>
          <el-option label="拒绝" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
      <el-form-item label="开始时间" prop="requestTimeRange">
        <el-date-picker
            v-model="queryModel.requestTimeRange"
            type="daterange"
            size="mini"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            value-format="yyyy-MM-dd"
            :clearable="false"
           style="width:300px"
            >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="审核时间" prop="auditTimeRange">
        <el-date-picker
            v-model="queryModel.auditTimeRange"
            type="daterange"
            size="mini"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            value-format="yyyy-MM-dd"
            :clearable="false"
           style="width:300px"
            >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <!-- <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchDelete"
        >删除选中项</el-button
      >
    </el-row> -->
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        type="index"
        label="序号"
        :index="indexMethod"
        width="50"
      ></el-table-column>
      <el-table-column
        prop="approvalNo"
        label="审批编号"
        width="180"
      ></el-table-column>
      <el-table-column prop="type" label="类型" width="100">
        <template slot-scope="{ row }">
          <span v-if="row.type == 1">外出</span>
          <span v-else-if="row.type == 3">出差</span>
          <span v-else>1</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" width="100">
        <template slot-scope="{ row }">
          <span v-if="row.status == 1" style="color: green">同意</span>
          <span v-else-if="row.status == 2" style="color: red">拒绝</span>
          <span v-else>待处理</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="personName"
        label="申请人员"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="personRoleName"
        label="申请人员角色"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="destination"
        label="目的地"
        width="150"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="startTime"
        label="开始时间"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="estimatedTime"
        label="预估时间"
        width="100"
      >
      <template slot-scope="{ row }">
          <span v-if="row.type == 1">{{row.estimatedTime}}小时</span>
          <span v-else-if="row.type == 3">{{row.estimatedTime}}天</span>
          <span v-else>1</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="endTime"
        label="结束时间"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="content"
        label="内容"
        width="150"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="approvalPersonName"
        label="审批人"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="approvalPersonRoleName"
        label="审批人角色"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="approvalTime"
        label="审核时间"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="approvalContent"
        label="审核意见"
        width="150"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="remark" label="备注" width="120"></el-table-column>
      <el-table-column label="操作" width="140" fixed="right">
        <template slot-scope="{ row }">
          <el-row>
            <el-col :span="12">
              <el-link v-if="row.type == 3" type="primary" @click="createWord(row)">下载</el-link>
            </el-col>
            <el-col :span="12">
              <el-link v-if="row.type == 3" type="success" @click="handleFlow(row)">审核详情</el-link>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <businessOut-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></businessOut-detail>
    <businessOut-flow
      v-if="showModal1"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></businessOut-flow>
  </div>
</template>
<script>
import Constant from "@/constant";
import BusinessOutDetail from "./businessOut-detail";
import BusinessOutFlow from "./businessOut-flow";
import businessOutApi from "@/api/base/businessOut";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "baseBusinessOutList",
  data() {
    var self = this;
    return {
      queryModel: {
        personName: "",
        type: "",
        status: "",
        approvalNo: "",
        requestTimeRange: ['',''],
        auditTimeRange: ['','']
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      showModal1: false,
      modalTitle: "",
      businessKey: "",
    };
  },
  methods: {
    createWord(record) {
      var self = this;
      self.loading = true;
      var formData = new FormData();
      formData.append("id", record.id);
      businessOutApi.createWord(formData).then(function (response) {
        var jsonData = response.data;
        self.loading = false;
        if (jsonData.result) {
          if (jsonData.data != null) {
            self.$message({
              showClose: true,
              type: "success",
              dangerouslyUseHTMLString: true,
              message: `<a href="${jsonData.data}" target="_blank">点击下载</a>&nbsp;`,
              duration: 30000,
            });
          }
        }
      });
    },
    indexMethod(index) {
      return (this.pageIndex - 1) * this.pageSize + (index + 1);
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("personName", self.queryModel.personName);
      formData.append("type", self.queryModel.type);
      formData.append("status", self.queryModel.status);
      formData.append("approvalNo", self.queryModel.approvalNo);

      if(self.queryModel.requestTimeRange[0] != null){
        formData.append("requestStartTime", self.queryModel.requestTimeRange[0]);
      }

      if(self.queryModel.requestTimeRange[1] != null){
        formData.append("requestEndTime", self.queryModel.requestTimeRange[1]);
      }

      if(self.queryModel.auditTimeRange[0] != null){
        formData.append("auditStartTime", self.queryModel.auditTimeRange[0]);
      }

      if(self.queryModel.auditTimeRange[1] != null){
        formData.append("auditEndTime", self.queryModel.auditTimeRange[1]);
      }


      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      businessOutApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleFlow(record) {
      this.modalTitle = "审核详情";
      this.businessKey = record.id;
      this.showModal1 = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          businessOutApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        businessOutApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;
      this.showModal1 = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "businessOut-detail": BusinessOutDetail,
    "businessOut-flow": BusinessOutFlow,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>